import React, { useState } from 'react';
import styles from './Framework.module.css';

const timeframes = [
  '5min', '15min', '30min', '45min', '1h', '2h', '4h', '8h', '12h',
  '1D', '2D', '3D', '1W', '2W', '1M', '3M', '1Y'
];

const Framework = () => {
  const [frameworks] = useState([]); // Replace with your frameworks state
  const [selectedFramework, setSelectedFramework] = useState('');
  const [frameworkName, setFrameworkName] = useState('');
  const [activeTimeframe, setActiveTimeframe] = useState('1D');
  const [biasTimeframes, setBiasTimeframes] = useState(['1W']);
  const [confirmationTimeframes, setConfirmationTimeframes] = useState(['4H']);

  const handleFrameworkSelection = (event) => {
    setSelectedFramework(event.target.value);
  };

  const handleFrameworkNameChange = (event) => {
    setFrameworkName(event.target.value);
  };

  const handleActiveTimeframeChange = (event) => {
    setActiveTimeframe(event.target.value);
  };

  const handleBiasTimeframeChange = (selected) => {
    setBiasTimeframes(timeframes.filter(tf => selected.includes(tf)));
  };

  const handleConfirmationTimeframeChange = (selected) => {
    setConfirmationTimeframes(timeframes.filter(tf => selected.includes(tf)));
  };

  const handleSaveFramework = () => {
    // Implement saving logic here
  };

  const handleReset = () => {
    setFrameworkName('');
    setActiveTimeframe('1D');
    setBiasTimeframes([]);
    setConfirmationTimeframes([]);
  };

  return (
    <div className={styles.frameworkContainer}>
      <div className={styles.header}>
        <select
          className={styles.frameworkSelect}
          value={selectedFramework}
          onChange={handleFrameworkSelection}
        >
          <option value="">Select Framework</option>
          {frameworks.map(fw => (
            <option key={fw} value={fw}>{fw}</option>
          ))}
          <option value="new">Create New Framework</option>
        </select>
        {selectedFramework === 'new' && (
          <>
            <input
              className={styles.inputField}
              type="text"
              placeholder="Framework Name"
              value={frameworkName}
              onChange={handleFrameworkNameChange}
            />
            <label htmlFor="activeTimeframe" className={styles.label}>Active Timeframe:</label>
            <select
              id="activeTimeframe"
              className={styles.timeframeSelect}
              value={activeTimeframe}
              onChange={handleActiveTimeframeChange}
            >
              {timeframes.map(tf => (
                <option key={tf} value={tf}>{tf}</option>
              ))}
            </select>
            <label>High Timeframe Bias:</label>
                <select 
                multiple={true}
                value={biasTimeframes} 
                onChange={(e) => setBiasTimeframes([...e.target.options].filter(o => o.selected).map(o => o.value))}>
                {timeframes.map(tf => (
                    <option key={tf} value={tf}>
                    {tf}
                    </option>
                ))}
                </select>

            <label>Lower Timeframe Confirmation:</label>
                <select 
                multiple={true}
                value={confirmationTimeframes} 
                onChange={(e) => setConfirmationTimeframes([...e.target.options].filter(o => o.selected).map(o => o.value))}>
                {timeframes.map(tf => (
                    <option key={tf} value={tf}>
                    {tf}
                    </option>
                ))}
                </select>
            <div className={styles.buttonContainer}>
              <button className={styles.saveButton} onClick={handleSaveFramework}>Save Framework</button>
              <button className={styles.resetButton} onClick={handleReset}>Reset</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Framework;
