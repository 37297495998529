import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import axios from 'axios';
import styles from './chart.module.css'; // Assuming you have this CSS module for styling
import TimeframeDropdown from './TimeframeDropdown'; // Assuming this component handles timeframe changes

// Since fixing the chart axes is a pain
const getChartOptions = (timeframe) => {
  let unit = 'day';
  let tooltipFormat = 'MM/dd/yyyy';
  let displayFormats = {};

  switch (timeframe) {
    case '1D':
      unit = 'hour';
      tooltipFormat = 'MM/dd/yyyy HH:mm';
      displayFormats = { hour: 'HH:mm' };
      break;
    case '1W':
      unit = 'day';
      displayFormats = { day: 'MM/dd' };
      break;
    case '1M':
      unit = 'month';
      displayFormats = { day: 'MM/dd' };
      break;
    default:
      break;
  }

  return {
    responsive: true,
    scales: {
      y: {
        beginAtZero: false,
        grid: {
          color: '#B3B3B3',
          borderColor: '#B3B3B3',
        },
        ticks: {
          color: '#B3B3B3',
        }
      },
      x: {
        type: 'time',
        time: {
          unit: unit,
          tooltipFormat: tooltipFormat,
          displayFormats: displayFormats,
        },
        grid: {
          color: '#B3B3B3',
          borderColor: '#B3B3B3',
        },
        ticks: {
          color: '#B3B3B3',
          maxRotation: 0,
          autoSkipPadding: 15
        }
      },
    },
    plugins: {
      legend: {
        display: false, // This will hide the legend
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: true,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          },
          title: function(context) {
            let title = context[0].label || '';
            if (title) {
              const date = new Date(title);
              title = date.toLocaleString(); // Or any other date format you prefer
            }
            return title;
          }
        }
      },
    },
    maintainAspectRatio: true,
  };
};

const Chart = ({ asset }) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [selectedTimeframe, setSelectedTimeframe] = useState('1D');

  useEffect(() => {
    const fetchData = async () => {
      if (!asset || !asset.ticker) {
        console.log("Asset data is missing or has been deleted.");
        return;
      }
  
      const url = `http://localhost:8000/api/chartdata/${asset.ticker}/${selectedTimeframe}`;
  
      try {
        const response = await axios.get(url);
        // Directly checking if the response data array is present and has length
        if (response.data && response.data.length > 0) {
          // Mapping through the data to prepare the labels and dataset
          const labels = response.data.map(result => new Date(result.timestamp));
          const data = response.data.map(result => result.close); // Using 'close' price for the data
  
          setChartData({
            labels,
            datasets: [{
              label: `${asset.name} Price`,
              data,
              fill: false,
              borderColor: '#72EFDD',
              borderWidth: 1.5,
              tension: 0.4,
              pointRadius: 0,
            }]
          });
        } else {
          console.log("No data available for the selected timeframe.");
          setChartData({ labels: [], datasets: [] });
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };
  
    fetchData();
  }, [asset, selectedTimeframe]);

  const handleTimeframeChange = (e) => {
    setSelectedTimeframe(e.target.value);
  };

  const options = getChartOptions(selectedTimeframe);

  return (
    <div className={styles.chartContainer}>
      <div className={styles.dropdownContainer}>
        <TimeframeDropdown selectedTimeframe={selectedTimeframe} onChange={handleTimeframeChange} />
      </div>
      {chartData.datasets.length > 0 ? (
        <Line data={chartData} options={options} />
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
  
};

export default Chart;
