import React from 'react';
import styles from './tradesignals.module.css';
import greenLight from '../../assets/images/Green_Light.png';
import greyLight from '../../assets/images/Grey_Light.png';
import redLight from '../../assets/images/Red_Light.png';

// Helper function to get the image based on the signal
const getIndicatorImage = (signalValue) => {
  switch (signalValue) {
    case 'positive':
      return greenLight;
    case 'neutral':
      return greyLight;
    case 'negative':
      return redLight;
    default:
      return greyLight;
  }
};

const TradeSignals = ({ signals = {} }) => {
  // Check if signals is not null or undefined and is an object
  if (!signals || typeof signals !== 'object' || Object.keys(signals).length === 0) {
    return <div>No trade signals available</div>;
  }

  // Extract all unique indicator names from the signals object
  const allIndicators = new Set();
  Object.values(signals).forEach(timeframe => {
    if (timeframe && typeof timeframe === 'object') {
      Object.keys(timeframe).forEach(indicator => {
        allIndicators.add(indicator);
      });
    }
  });

  // Convert Set to Array for mapping
  const indicatorsArray = Array.from(allIndicators);

  return (
    <div className={styles.tradingSignalsContainer}>
      <div className={styles.signalHeader}>
        <div className={styles.timeframe}>Timeframe</div>
        {indicatorsArray.map(indicator => (
          <div key={indicator} className={styles.indicatorHeader}>{indicator}</div>
        ))}
      </div>
      {Object.entries(signals).map(([timeframe, indicators], index) => (
        <div key={index} className={styles.signalRow}>
          <div className={styles.timeframe}>{timeframe}</div>
          {indicatorsArray.map(indicator => (
            <div key={indicator} className={styles.indicator}>
              {/* Ensure indicator exists in the current timeframe before accessing it */}
              <img src={getIndicatorImage(indicators?.[indicator])} alt={indicator} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TradeSignals;