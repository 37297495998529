// TimeframeDropdown.jsx
import React from 'react';
import styles from './timeframeDropdown.module.css'; 

const TimeframeDropdown = ({ selectedTimeframe, onChange }) => {
  const timeframeOptions = [
    '5min', '15min', '30min', '45min', '1h', '2h', '4h', '8h', '12h',
    '1D', '2D', '3D', '1W', '2W', '1M', '3M', '1Y'
  ];

  return (
    <div className={styles.dropdownContainer}>
      <select className={styles.dropdown} value={selectedTimeframe} onChange={onChange}>
        {timeframeOptions.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

export default TimeframeDropdown;
