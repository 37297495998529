import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import axios from 'axios';

const MiniChart = ({ asset }) => {
  const [chartData, setChartData] = useState({ datasets: [] });

  useEffect(() => {
    const fetchData = async () => {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - 7); // Last 7 days

      const formatDateString = date => date.toISOString().split('T')[0];
      const ticker = asset.ticker; // Assuming 'asset' prop includes 'ticker'
      const apiKey = 'Xxk3UNeg54CYpSVCsrf3OtYLxPAW479_'; // Place your Polygon API key here

      try {
        const url = `https://api.polygon.io/v2/aggs/ticker/${ticker}/range/1/hour/${formatDateString(startDate)}/${formatDateString(endDate)}`;
        const response = await axios.get(url, {
          params: {
            apiKey: apiKey,
            limit: 50000 // Adjust based on your needs
          },
        });
        if (response.data && response.data.results) {
          const labels = response.data.results.map(result => new Date(result.t).toLocaleTimeString());
          const data = response.data.results.map(result => result.c); // 'c' for close prices
          setChartData({
            labels,
            datasets: [{
              label: `${asset.name} Price`, // Assuming 'asset' prop includes 'name'
              data: data,
              fill: false,
              borderColor: '#72EFDD',
              borderWidth: 1.5, // Set this to your preferred thickness, e.g., 1 for a thinner line
              tension: 0.1,
              pointRadius: 0, // Set point radius to 0 to remove the points
            }]
          });
        }
      } catch (error) {
        console.error('Error fetching MiniChart data:', error);
      }
    };

    if(asset && asset.ticker) {
      fetchData();
    }
  }, [asset]);

  const options = {
    responsive: true,
    scales: {
      x: {
        display: false, // Hide x-axis labels
      },
      y: {
        display: false, // Hide y-axis labels
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    elements: {
      line: {
        tension: 0.1, // Smooths the line
      },
    },
    maintainAspectRatio: true, // Ensures chart is responsive
  };

  return (
    <div style={{ width: '100px', height: '50px' }}>
      {chartData.datasets.length > 0 ? (
        <Line data={chartData} options={options} />
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
};

export default MiniChart;
