// App.jsx
import React from 'react';
import './App.css';
import Navbar from './components/navbar/navbar'; 
import Dashboard from './components/dashboard/dashboard'; // Import Dashboard component
import Footer from './components/footer/footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <main>
        <Dashboard /> {/* Dashboard component that includes Watchlist, Alerts, TradeSignals */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
