import React from 'react';
import styles from './WatchlistForm.module.css';

const WatchlistForm = ({ availableAssets, onAdd, onCancel }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const assetId = formData.get('asset-id'); // Assuming the select name is 'asset-id'
    
    if (!assetId) {
      console.error('No asset selected');
      return;
    }
  
    onAdd(parseInt(assetId, 10)); // Make sure assetId is passed as a number
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <select name="asset-id" className={styles.dropdown} defaultValue="" required>
        <option value="" disabled>Select an asset</option>
        {availableAssets.map((asset) => (
          <option key={asset.id} value={asset.id}>{asset.name}</option>
        ))}
      </select>
      <div className={styles.buttonGroup}>
        <button type="submit" className={styles.addButton}>Add</button>
        <button type="button" className={styles.cancelButton} onClick={onCancel}>Cancel</button>
      </div>
    </form>
  );
};

export default WatchlistForm;
