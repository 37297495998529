import React, { useState } from 'react';
import styles from './IndicatorSettings.module.css';

const IndicatorSettings = () => {
  const [activeTab, setActiveTab] = useState('trend');
  const [activeSubTab, setActiveSubTab] = useState('movingAverages');

  return (
    <div className={styles.indicatorSettingsContainer}>
      <div className={styles.tabs}>
        <button
          onClick={() => setActiveTab('trend')}
          className={activeTab === 'trend' ? styles.activeTab : ''}
        >
          Trend
        </button>
        <button
          onClick={() => setActiveTab('momentum')}
          className={activeTab === 'momentum' ? styles.activeTab : ''}
        >
          Momentum
        </button>
        <button
          onClick={() => setActiveTab('priceStructure')}
          className={activeTab === 'priceStructure' ? styles.activeTab : ''}
        >
          Price Structure
        </button>
        {/* ...other tabs */}
      </div>

      {activeTab === 'trend' && (
        <div className={styles.subTabs}>
          <button
            onClick={() => setActiveSubTab('movingAverages')}
            className={activeSubTab === 'movingAverages' ? styles.activeSubTab : ''}
          >
            Moving Averages
          </button>
          <button
            onClick={() => setActiveSubTab('priceDifferences')}
            className={activeSubTab === 'priceDifferences' ? styles.activeSubTab : ''}
          >
            Price Differences
          </button>
          <button
            onClick={() => setActiveSubTab('ichimokuCloud')}
            className={activeSubTab === 'ichimokuCloud' ? styles.activeSubTab : ''}
          >
            Ichimoku Cloud
          </button>
          {/* ...other second-level tabs */}
        </div>
      )}

      <div className={styles.tabContent}>
        {activeTab === 'trend' && activeSubTab === 'movingAverages' && <p>Coming Soon</p>}
        {activeTab === 'trend' && activeSubTab === 'priceDifferences' && <p>Coming Soon</p>}
        {activeTab === 'trend' && activeSubTab === 'ichimokuCloud' && <p>Coming Soon</p>}
        {/* ...other tab contents */}
      </div>
    </div>
  );
};

export default IndicatorSettings;
