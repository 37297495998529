import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './dashboard.module.css';
import Chart from '../chart/chart';
import Watchlist from '../watchlist/watchlist';
import TradeSignals from '../tradesignals/tradesignals';
import IndicatorSettings from '../IndicatorSettings/IndicatorSettings';
import Modal from '../common/modal/Modal';
import LoadingIndicator from '../common/LoadingIndicator';
import Framework from '../Framework/Framework';


const Dashboard = () => {
  const [watchlistAssets, setWatchlistAssets] = useState([]);
  const [availableAssets, setAvailableAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedWatchlistId, setSelectedWatchlistId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    axios.get('http://127.0.0.1:8000/api/watchlists/')
      .then(response => {
        if (response.data && response.data.length > 0) {
          setSelectedWatchlistId(response.data[0].id);
          return axios.get(`http://127.0.0.1:8000/api/watchlists/${response.data[0].id}/`);
        }
        throw new Error('No watchlists found');
      })
      .then(watchlistResponse => {
        setWatchlistAssets(watchlistResponse.data.assets || []);
        if (watchlistResponse.data.assets && watchlistResponse.data.assets.length > 0) {
          setSelectedAsset(watchlistResponse.data.assets[0]);
        }
      })
      .catch(error => setError(`Error fetching data: ${error.message}`))
      .finally(() => setIsLoading(false));

    axios.get('http://127.0.0.1:8000/api/assets/')
      .then(response => setAvailableAssets(response.data))
      .catch(error => console.error('Error fetching available assets:', error));
  }, []);

  const fetchWatchlist = async () => {
    try {
      const response = await axios.get(`http://127.0.0.1:8000/api/watchlists/${selectedWatchlistId}/`);
      if (response.data && response.data.assets) {
        setWatchlistAssets(response.data.assets);
        if (response.data.assets.length > 0) {
          setSelectedAsset(response.data.assets[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching watchlist:', error);
    }
  };

  const handleSelectAsset = (assetId) => {
    const asset = watchlistAssets.find(asset => asset.id === assetId);
    setSelectedAsset(asset);
  };

  const addAssetToWatchlist = async (assetId) => {
    try {
      await axios.post(`http://127.0.0.1:8000/api/watchlists/${selectedWatchlistId}/add_asset/`, { asset_id: assetId });
      await fetchWatchlist();
    } catch (error) {
      console.error('Error adding asset:', error.response);
    }
  };

  const removeAssetFromWatchlist = async (assetId) => {
    if (window.confirm("Are you sure you want to remove this asset from the watchlist?")) {
      try {
        await axios.delete(`http://127.0.0.1:8000/api/watchlists/${selectedWatchlistId}/remove_asset/${assetId}/`);
        const updatedAssets = watchlistAssets.filter(asset => asset.id !== assetId);
        setWatchlistAssets(updatedAssets);
        if (updatedAssets.length > 0) {
          setSelectedAsset(updatedAssets[0]);
        } else {
          setSelectedAsset(null);
        }
      } catch (error) {
        console.error("Failed to remove asset from watchlist:", error.response);
      }
    }
  };

  return (
    <div className={styles.dashboardContainer}>
      <Modal isOpen={isLoading || error} onClose={() => setError('')}>
        {isLoading && <LoadingIndicator />}
        {error && <p>{error}</p>}
      </Modal>
      <div className={styles.dashboardContent}>
        <div className={styles.leftColumn}>
          <div className={styles.cardStyle}>
            <h2 className={styles.headerLabel}>Watchlist</h2>
              <Watchlist
                watchlistId={selectedWatchlistId} // Pass the selected watchlist ID as a prop
                assets={watchlistAssets}
                availableAssets={availableAssets}
                onSelectAsset={handleSelectAsset}
                selectedAssetId={selectedAsset?.id}
                onAddAsset={addAssetToWatchlist}
                onRemoveAsset={removeAssetFromWatchlist}
              />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.chartCardStyle}>
            <h2 className={styles.headerLabel}>{selectedAsset ? `${selectedAsset.name} Chart` : 'Select an Asset'}</h2>
            {selectedAsset && <Chart asset={selectedAsset} />}
          </div>
          <div className={styles.cardStyle}>
            <h2 className={styles.headerLabel}>Framework</h2>
            <Framework />
          </div>
          <div className={styles.cardStyle}>
            <h2 className={styles.headerLabel}>Trade Signals</h2>
            {selectedAsset && <TradeSignals asset={selectedAsset} />}
          </div>
          <div className={styles.cardStyle}>
            <h2 className={styles.headerLabel}>Strategies</h2>
            {selectedAsset && <IndicatorSettings asset={selectedAsset} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
