import React from 'react';
import styles from './navbar.module.css';

function Navbar() {
    return (
        <nav className={styles.navbar}>
            <ul className={styles.navLinks}>
                <li><a href="/home">Home</a></li>
                <li><a href="/watchlist">Watchlist</a></li>
                <li><a href="/alerts">Alerts</a></li>
                <li><a href="/settings">Settings</a></li>
                <li><a href="/help">Help</a></li>
            </ul>
        </nav>
    );
}

export default Navbar;
