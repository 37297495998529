// LoadingIndicator.jsx
import React from 'react';
import styles from './LoadingIndicator.module.css'; 

const LoadingIndicator = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.spinner}></div> 
      <p className={styles.loadingText}>Loading data...</p>
    </div>
  );
};

export default LoadingIndicator;
