import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './watchlist.module.css';
import MiniChart from './MiniChart.jsx';
import WatchlistForm from './WatchlistForm.jsx';
import Modal from '../common/modal/Modal';

const Watchlist = ({ onSelectAsset, selectedAssetId, onAddAsset, onRemoveAsset, availableAssets }) => {
  const [watchlist, setWatchlist] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    axios.get('http://127.0.0.1:8000/api/watchlists/') // Ensure correct endpoint
      .then(response => {
        const firstWatchlist = response.data.length > 0 ? response.data[0] : null;
        setWatchlist(firstWatchlist ? firstWatchlist.assets : []);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching watchlist:', error);
        setError('Failed to fetch watchlist');
        setLoading(false);
      });
  }, []);

  // Logic to handle click on the add button
  const handleAddClick = () => setModalOpen(true);

  // In your Watchlist component

  // Temporary mock function for removal
  const handleRemoveAsset = (assetId) => {
    if (window.confirm("Are you sure you want to remove this asset from the watchlist?")) {
      onRemoveAsset(assetId);
    }
  };

  // This function gets called with the selected asset ID
  const handleSubmit = (assetId) => {
    onAddAsset(assetId); // Pass only the asset ID to the onAddAsset
    setModalOpen(false);
  };

  // Logic to handle modal cancellation
  const handleCancel = () => setModalOpen(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className={styles.watchlistContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Asset</th>
            <th>Price</th>
            <th>Chart</th>
            <th>Status</th>
            <th>Framework</th>
            <th>Backtesting</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {watchlist.map((asset) => (
            <tr 
              key={asset.id} 
              onClick={() => onSelectAsset(asset.id)}
              className={selectedAssetId === asset.id ? styles.selectedAssetRow : ''}
            >
              <td>{asset.name}</td>
              <td>{asset.current_price ? `$${asset.current_price}` : 'N/A'}</td>
              <td>
                <MiniChart asset={asset} />
              </td>
              <td>'N/A'</td> {/* Placeholder until status is available */}
              <td>'N/A'</td> {/* Placeholder until framework data is available */}
              <td>'N/A'</td> {/* Placeholder until backtesting data is available */}
              <td><button onClick={() => handleRemoveAsset(asset.id)}>Delete</button></td>
            </tr
            >
          ))}
        </tbody>
      </table>
      <div className={styles.addAssetRow} onClick={handleAddClick}>
        <span className={styles.addAssetText}>+ Add Asset</span>
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleCancel}>
          <div className="modalOverlay">
            <div className="modalContent">
              {/* Make sure availableAssets is being passed here */}
              <WatchlistForm
                availableAssets={availableAssets}
                onAdd={handleSubmit}
                onCancel={handleCancel}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Watchlist;

